<template>
  <v-container fluid>
    <v-card>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1"
            ><v-icon size="40">mdi-account-multiple</v-icon> QUẢN LÝ KHU
            VỰC</v-list-item-title
          >
          <v-list-item-subtitle>Danh sách khu vực</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn color="secondary" @click="addKhuVuc">
          <v-icon color="white">mdi-plus</v-icon>
          <span style="color: white" class="btn-add">Thêm khu vực</span>
        </v-btn>
      </v-list-item>
    </v-card>
    <br />
    <v-card class="mb-1">
      <v-data-table
        :headers="headers"
        :items="tableData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :loading="loading"
        class="elevation-1"
        loading-text="Đang tải dữ liệu ..."
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="textColor">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="edit(item)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-pencil </v-icon>
                  Chi tiết</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="destroy(item.id)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-delete </v-icon>
                  Xóa khu vực</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <div class="pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="changePage"
        :total-visible="10"
        >></v-pagination
      >
    </div>
    <create-edit @fetch-data="fetchData" ref="hopdongform"></create-edit>
  </v-container>
</template>
<script>
import CreateEdit from "./create-edit";
import { getData, show, destroy } from "@/api/khuvuc";
export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      menu: {},
      loading: false,
      search: "",
      roleId: null,
      roles: [],
      trang_thai: null,
      headers: [
        { text: "Tên khu vực", value: "name", sortable: false },
        {
          text: "Thời gian đi",
          align: "start",
          sortable: false,
          value: "thoi_gian_di",
        },
        {
          text: "Ghi chú",
          align: "start",
          sortable: false,
          value: "ghi_chu",
        },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const res = await getData({
          page: this.page,
        });
        this.tableData = res.data.data;
        this.page = res.data.current_page;
        this.pageCount = res.data.last_page;
      } finally {
        this.loading = false;
      }
    },
    async destroy(id) {
      this.$confirmBox.show({
        title: "Xóa Khu vực",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa khu vực này",
        action: () => destroy(id),
        onDone: () => this.fetchData(),
      });
    },
    changePage(val) {
      this.page = val;
      this.fetchData();
    },
    notify(isSuccess = true, mess) {
      let params = {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      };
      if (isSuccess) {
        this.$toast.info(`${mess} thành công`, {
          timeout: 2000,
          ...params,
        });
      } else {
        this.$toast.error(`${mess} thất bại`, {
          timeout: 6000,
          ...params,
        });
      }
    },
    addKhuVuc() {
      this.$refs.hopdongform.open(true);
    },
    async edit(data) {
      this.$refs.hopdongform.open(false, data);
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .btn-add {
    display: none;
  }
}
</style>
