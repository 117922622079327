<template>
  <v-dialog v-model="dialog" persistent :width="width">
    <v-card>
      <v-card-title class="headline text-uppercase">{{
        isCreate ? `CẬP NHẬT ${title}` : `THÊM MỚI ${title}`
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <slot></slot>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="$emit('create')"
          v-if="isCreate"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="$emit('update')"
          v-else
        >
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    title: String,
    isCreate: Boolean,
    loading: Boolean,
    width: { type: [String,Number], default: "800" },
  },
  data: () => ({}),
  computed: {
    dialog: {
      set(value) {
        if (!this.value) {
          this.$emit("close");
        }
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {},
};
</script>