<template>
  <DialogCreateEdit
    :isCreate="isCreate"
    title="Thông tin khu vực"
    v-model="show"
    :width="600"
    :loading="loading"
    @update="update"
    @create="create"
  >
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <div class="label-form">Tên khu vực</div>
          <v-text-field
            v-model="form.name"
            placeholder="Nhập tên khu vực"
            outlined
            dense
            prepend-inner-icon="mdi-map"
            hide-details="auto"
            :rules="formValidate.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="label-form">Thời gian đi đường (phút)</div>
          <v-text-field
            v-model="form.thoi_gian_di"
            :rules="formValidate.thoi_gian"
            placeholder="Số phút đi đường"
            type="number"
            outlined
            dense
            prepend-inner-icon="mdi-clock"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="label-form">Ghi chú</div>
          <v-textarea
            placeholder="Ghi chú về khu vực"
            rows="2"
            outlined
            dense
            prepend-inner-icon="mdi-note"
            hide-details="auto"
            v-model="form.ghi_chu"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
  </DialogCreateEdit>
</template>
<script>
import DialogCreateEdit from "@/components/dialog-create-edit";
import { update, store } from "@/api/khuvuc";
export default {
  components: {
    DialogCreateEdit,
  },
  data: () => ({
    form: {},
    show: false,
    loading: false,
    isCreate: false,
    formValidate: {
      name: [(v) => !!v || "Hãy nhập tên khu vực!"],
      thoi_gian: [
        (v) => !!v || "Thời gian không được để trống!",
        (v) => /^\d+$/.test(v) || "Thời gian không hợp lệ",
      ],
    },
  }),
  methods: {
    async create() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const res = await store({
            ...this.form,
            nhan_vien_id: this.$route.params.id,
          });
          this.closeSubmit();
        } catch (err) {
          this.errorSubmit();
        } finally {
          this.loading = false;
        }
      }
    },
    async update() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const res = await update(this.form, this.form.id);
          this.closeSubmit();
        } catch (err) {
          this.errorSubmit();
        } finally {
          this.loading = false;
        }
      }
    },
    closeSubmit() {
      this.show = false;
      this.$emit("fetch-data");
      this.$toast.info(
        `${this.isCreate ? "Thêm mới" : "Cập nhật"} thành công`,
        {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        }
      );
    },
    errorSubmit() {
      this.$toast.error(`${this.isCreate ? "Thêm mới" : "Cập nhật"} thất bại`, {
        position: "top-center",
        timeout: 6000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    open(isCreate, form = {}) {
      this.isCreate = isCreate;
      if (!isCreate) {
        this.form = { ...this.form, ...form };
      }
      this.show = true;
    },
  },
};
</script>